import React from 'react';
import { useContainer } from '@bcmi-labs/react-container';

import { Config } from '../Config';

export function App() {
  const config = useContainer<Config>(Config);
  return (
    <div className="main">
      <h1>Welcome to the Arduino SPA Boilerplate!</h1>
      <code>
        {`This site is using `}
        <b>{config.APP_ENV}</b>
        {` environment config`}
      </code>
      <br />
      <br />
      <code>{JSON.stringify(config)}</code>
    </div>
  );
}
