"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var decorators_1 = require("./decorators");
function getDecorators(container, doCache) {
    if (doCache === void 0) { doCache = true; }
    var lazyInject = decorators_1.makePropertyInjectDecorator(container, doCache);
    var lazyInjectNamed = decorators_1.makePropertyInjectNamedDecorator(container, doCache);
    var lazyInjectTagged = decorators_1.makePropertyInjectTaggedDecorator(container, doCache);
    var lazyMultiInject = decorators_1.makePropertyMultiInjectDecorator(container, doCache);
    return {
        lazyInject: lazyInject,
        lazyInjectNamed: lazyInjectNamed,
        lazyInjectTagged: lazyInjectTagged,
        lazyMultiInject: lazyMultiInject
    };
}
exports.default = getDecorators;
