import { interfaces } from 'inversify';
import { IModule } from '@bcmi-labs/react-container';

import { Config } from '../Config';

export class MainModule implements IModule {
  constructor(private config: Config) {}

  public modules(container: interfaces.Container): void {
    container.bind<Config>(Config).toConstantValue(this.config);
  }
}
