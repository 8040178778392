import 'whatwg-fetch';
import 'reflect-metadata';
import 'regenerator-runtime/runtime';

import * as React from 'react';
import { Application } from '@bcmi-labs/react-container';

import { App } from './views/App';
import { Config } from './Config';
import { MainModule } from './modules/MainModule';

const app = new Application();

fetch(process.env.CONFIG_URL || '/config.json')
  .then((res) => res.json())
  .then((config: Config) => {
    app.register(new MainModule(config));
    app.run(() => <App />);
  });
